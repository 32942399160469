<template>
<div>
  <el-tabs :tab-position='tabPosition' class='performance-tab'  v-if='userPermissions.indexOf("ecp_bonus")>-1'>
<!--    <el-tab-pane label='项目组' v-if="userPermissions.indexOf('ecp_bonus_team')>-1">-->
      <TeamBonus  />
<!--    </el-tab-pane>-->
<!--    <el-tab-pane label='招商部' v-if="userPermissions.indexOf('ecp_bonus_merchant')>-1" >-->
<!--      <MerchantBonus />-->
<!--    </el-tab-pane>-->
  </el-tabs>
</div>
</template>

<script>
import MerchantBonus from '@/pages/commerce/bonus/MerchantBonus'
import TeamBonus from '@/pages/commerce/bonus/TeamBonus'
import { mapGetters } from 'vuex'
export default {
  name: 'index',
  components: { TeamBonus, MerchantBonus },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      tabPosition: 'left'
    }
  }
}
</script>

<style scoped>

</style>
