<template>
  <div>
    <el-form :model='formData' label-width='100px' label-position='right'>
      <el-form-item label='选择部门：'>
        <EcpDeptSelect ref='refEcpDeptSelect' @handleSelect='selectDept' />
      </el-form-item>
      <el-form-item label='部门成员：'>
        <div style='display: block;'>
          <span v-if='dept_group.name'> 『{{ dept_group.name || '' }}』</span>
          <el-checkbox v-model='checkAll' @change='handleCheckAll'>选中『在职人员』</el-checkbox>
          <!--            <el-button type='primary' @click='defaultSelected' icon='el-icon-check'>选中『在职人员』</el-button>-->
          <br />
          <div class='member-box' v-if='dept_group.members.length>0'>
            <el-checkbox-group v-model='formData.leader_ids' @change='handleChange'>
              <el-checkbox v-for='(member,index) in dept_group.members' :key='member.userid'
                           :label='member'>
                <span :title='`钉钉用户ID：${member.userid}`'>  {{ index + 1 }}）{{ member.name }}</span>
                <span v-if='member.position'> 『{{ member.position }}』</span>
                <span v-if="member.is_leave==='Y'" style='color: #F56C6C'>({{ member.is_leave_alias }})</span>
                <span v-else>({{ member.is_leave_alias }})</span>
              </el-checkbox>
            </el-checkbox-group>
          </div>

        </div>
      </el-form-item>
      <el-form-item label='上级部门：'>
        <div style='display: block;'>
          <span v-if='dept_manage.name'> 『{{ dept_manage.name || '' }}』</span>
          <br />
          <div class='member-box' v-if='dept_manage.members.length>0'>
            <el-checkbox-group v-model='formData.m_leader_ids' @change='handleChange'>
              <el-checkbox v-for='(member,index) in dept_manage.members' :key='member.userid'
                           :label='member'>
                <span :title='`钉钉用户ID：${member.userid}`'> {{ index + 1 }}） {{ member.name }}</span>
                <span v-if='member.position'> 『{{ member.position }}』</span>
                <span v-if="member.is_leave==='Y'" style='color: #F56C6C'>({{ member.is_leave_alias }})</span>
                <span v-else>({{ member.is_leave_alias }})</span>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import EcpDeptSelect from '@/pages/commerce/kol_mapper/EcpDeptSelect'

export default {
  name: 'BonusMemberSelection',
  components: { EcpDeptSelect },
  props: {
    deptName: {
      type: String,
      default() {
        return ''
      }
    },
    defaultSelectNoLeave: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  data() {
    return {
      loadingCommit: false,
      formData: {
        leader_ids: [],
        m_leader_ids: [],
        group_id: null,
        group_name: null,
        dept_id: null,
        dept_name: null,
        leader_id: null,
        leader_name: null,
        m_leader_id: null,
        m_leader: null
      },
      dept_group: {
        dpt_id: '',
        name: '',
        members: []
      },
      dept_manage: {
        dpt_id: '',
        name: '',
        members: []
      },
      labelMap: [
        { position: '总监', position_label: 1 },
        { position: '招商专员', position_label: 2 },
        { position: '招商助理', position_label: 3 },
        { position: '采购助理', position_label: 3 }
      ],
      checkAll: false,
      selectMembers: [],//勾选的成员
      initMembers: []//已初始化的
    }
  },
  methods: {
    changeDept() {
      // this.$notify.success('选择默认部门')
      setTimeout(() => {
        if (this.$refs['refEcpDeptSelect'])
          this.$refs['refEcpDeptSelect'].changeDeptByName(this.deptName)
      }, 500)
    },
    async selectDept(arr) {
      //项目部2-组3
      if (arr && arr.length >= 2) {
        this.formData.dept_id = arr[arr.length - 2].dpt_id
        this.formData.group_id = arr[arr.length - 1].dpt_id
        // let deptNames = arr.map(_ => {
        //   return _.name
        // })
        // this.formData.dept_name = deptNames.join('/')
        this.formData.dept_name = arr[arr.length - 2].name
        this.formData.group_name = arr[arr.length - 1].name

        // 部门成员加载
        if (arr[arr.length - 2]) {
          let dept_m = arr[arr.length - 2]
          this.dept_manage = await this.getGroupMembers({ dpt_id: dept_m.dpt_id, name: dept_m.name })
        }
        //组员成员加载 0,1,2
        if (arr[arr.length - 1]) {
          let dept_g = arr[arr.length - 1]
          this.dept_group = await this.getGroupMembers({ dpt_id: dept_g.dpt_id, name: dept_g.name })
        }
        //默认勾选在线人员
        setTimeout(() => {
          this.defaultSelected()
        }, 100)

      } else {
        this.formData.dept_id = null
        this.formData.dept_name = null
      }
    },
    //获取部门信息及其成员
    async getGroupMembers(dept) {

      let members = []
      let searchCondition = { page_size: 50, dpt_id: dept.dpt_id, current_page: 1 }
      let { list, pages } = await this.$api.getDptUsers(searchCondition)
      members = [...members, ...list]
      searchCondition.page_size = pages.page_size
      searchCondition.current_page = pages.current_page
      for (let i = pages.offset + list.length; i < pages.total; i += pages.page_size) {
        searchCondition.current_page += 1
        let { list } = await this.$api.getDptUsers(searchCondition)
        members = [...members, ...list]
      }
      return { dpt_id: dept.dpt_id, name: dept.name, members: members }
    },
    handleCheckAll() {
      this.formData.m_leader_ids = []
      this.formData.leader_ids = []
      if (this.checkAll) {
        //默认勾选在职
        this.dept_manage.members.forEach(member => {
          if (member.is_leave !== 'Y') {
            this.formData.m_leader_ids.push(member)
          }
        })

        this.dept_group.members.forEach(member => {
          if (member.is_leave !== 'Y') {
            this.formData.leader_ids.push(member)
          }
        })
      }
      this.handleChange()
    },
    defaultSelected() {

      this.formData.m_leader_ids = []
      this.formData.leader_ids = []
      //默认勾选在职
      if(this.defaultSelectNoLeave){
        this.dept_manage.members.forEach(member => {
          if (member.is_leave !== 'Y') {
            this.formData.m_leader_ids.push(member)
          }
        })
        // this.handleChange()
        this.dept_group.members.forEach(member => {
          if (member.is_leave !== 'Y') {
            this.formData.leader_ids.push(member)
          }
        })
        this.checkAll = true
        //切换
        this.handleChange()
      }

    },
    handleChange() {
      let data = {
        dept_id: this.formData.dept_id,
        dept_name: this.formData.dept_name,
        group_id: this.formData.group_id,
        group_name: this.formData.group_name,
        selectMembers: [...this.formData.leader_ids, ...this.formData.m_leader_ids]
      }
      // console.log(data)
      this.$emit('selected', data)
    }
  }
}
</script>

<style scoped>

</style>