<template>
  <div>
    <el-dialog v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose' width='1100px' :title='dialogTitle'>
      <el-form :inline='false' :model='formData' label-width='100px' label-position='right' style='width: 1000px'>
        <div class='card'>
          <div class='card-box'>
            <bonus-member-selection ref='refBonusMemberSelection' :dept-name='formData.group_name'
                                    :default-select-no-leave='false' @selected='handleSelectMembers' />
            <el-divider posiiton='center'>
              成员
            </el-divider>
            <div>
              <el-tag size='medium' effect='plain' type='primary' v-for='member in formData.members' :key='member.name'
                      style='margin-left: 10px'>
                {{ member.name || '' }}
              </el-tag>
            </div>
          </div>
        </div>
      </el-form>
      <div slot='footer' style='text-align: center'>
        <el-button type='primary' size='medium' @click='handleAdd'>确认添加</el-button>
        <el-button  icon='el-icon-close' size='medium' @click='close'>关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BonusMemberSelection from '@/pages/commerce/bonus/BonusMemberSelection'

export default {
  name: 'AddMember',
  components: { BonusMemberSelection },
  props: {
    id: {
      type: [String, Number],
      default() {
        return null
      }
    },
    searchCondition: {
      type: Object,
      default() {
        return {
          year: null, quarter: null
        }
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    dialogTitle() {
      return `添加成员-项目组奖金分配`
    }
  },
  data() {
    return {
      loadingCommit: false,
      formData: {
        'year': null,
        'month': null,
        'quarter': null,
        'group_id': null,
        'group_name': null,
        'bonus': null,
        'last_total_profit': null,
        members: []
      }
    }
  },
  methods: {
    onOpen() {
      this.loadingCommit = false
      this.formData.members = []
      this.getInfo()
    },
    onClose() {

    },
    close() {
      this.$emit('update:visible', false)
    },
    async getInfo() {
      let { info } = await this.$api.getEcpBonusMonthInfo(this.id)
      info.members = []
      this.formData = info

      //默认选择 当前分组，展示其下可选成员
      this.$refs['refBonusMemberSelection'].changeDept()

    },
    handleSelectMembers(data) {
      this.formData.members = [...data.selectMembers]
    },
    async handleAdd() {
      // console.log(this.formData.members)
      let data = this.formData.members.map((item) => {
        return {
          'pid': this.formData.id,
          'name': item.name,
          'userid': item.userid,
          'position': item.position,
          'group_id': item.dpt_id,
          'group_name': item.dpt_name
        }
      })

      let { success_total, fail_list } = await this.$api.batchAddEcpBonusMonthMember({ 'members': data })
      if (fail_list && fail_list.length > 0) {
        let nameArr = fail_list.map(_ => {
          return _.name
        })
        this.$notify.warning(`初始化：${data.length},成功:${success_total},失败：${fail_list.length},分别是：${nameArr.join('、')}`)
        return false
      } else {
        this.$notify.success(`成功添加[${data.length}]成员`)
        setTimeout(() => {
          this.close()
        }, 500)
      }
    }
  }
}
</script>

<style scoped>

</style>
