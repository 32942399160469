<template>
  <div>
    <el-dialog :title='dialogTitle' append-to-body v-bind='$attrs' width='60%' @close='onClose' @open='onOpen'
               v-on='$listeners'>
      <el-row :gutter='15'>
        <el-col :span='10'>
          <el-card shadow="never" header="添加成员" class='member'>
            <el-form ref='elForm' :model='formData' :rules='rules' label-width='80px'>
              <el-form-item label='组名'>
                <EcpDeptSelect class='select-custom' ref='ecp_dept'
                               style="width: 80%;display: inline-block" @handleSelect='selectCurrentDept'/>
                <!--                <DeptCascaderScope ref='refDeptCascaderScope' :selectUrl="selectUrl" :show-first-group='false'
                                                   :type='`bonus_data`' @selectedDept='selectCurrentDept'/>-->
              </el-form-item>
              <el-form-item label='月份'>
                <el-date-picker
                    v-model='formData.ym'
                    format='yyyy年MM月'
                    placeholder='选择月度'
                    style='width: 140px'
                    type='month' value-format='yyyy-M'>
                </el-date-picker>
              </el-form-item>
              <el-form-item label='成员'>
                <template slot='label'>
                  <span>成员({{ formData.members.length }})</span>
                </template>
                <el-tag
                    v-for='tag in formData.members'
                    :key='tag.userid'
                    closable
                    effect='plain'
                    size='small'
                    @close='handleClose(tag)'
                >
                  {{ tag.name }}
                </el-tag>
              </el-form-item>
              <el-form-item>
                <el-button v-if="userPermissions.indexOf('ecp_bonus_add_member')>-1" :disabled='loadingCommit'
                           :loading='loadingCommit' type='primary'
                           @click='handleConfirm'>
                  确认添加
                </el-button>
              </el-form-item>
            </el-form>
          </el-card>

        </el-col>

        <el-col :span='14'>
          <el-card shadow="never" header="选择成员" class='dpt'>
            <div>
              <el-form>
                <el-form-item label='选择分组'>
                  <!--                  <DeptCascader ref='refDeptCascaderScope' :selectUrl="selectUrl"-->
                  <!--                                :show-first-group='false' @selectedDept='selectDept'/>-->
                  <EcpDeptSelect class='select-custom' ref='ecp_dept'
                                 style="width: 80%;display: inline-block" @handleSelect='selectDept'/>
                </el-form-item>
                <el-form-item label='搜索成员'>
                  <UserSelect :leader-id.sync="selectId"
                              @selected="handleLeaderSelect"/>
                </el-form-item>
                <el-form-item label='分组成员'>
                  <div style='display: block;margin-left: 10px'>
                    <el-checkbox v-model='checkAll' :indeterminate='isIndeterminate' style='float: left'
                                 @change='handleCheckAllChange'>
                      全选({{ checkedMembers.length }}/{{ members.length }})
                    </el-checkbox>
                  </div>
                  <el-row>
                    <el-col :span='24'>
                      <div style='display: block;'>
                        <el-checkbox-group v-model='checkedMembers' @change='handleCheckedCitiesChange'>
                          <el-checkbox v-for='member in members' :key='member.userid' :label='member' border
                                       size='large'>
                            <el-tooltip :content='member.position' effect='light' placement='bottom'>
                              <span>{{ member.name }} <span>{{ encryptMobile(member.mobile) }}</span></span>
                            </el-tooltip>

                            <!--                          <span style="font-weight: lighter" v-if="member.position"> / {{ member.position }}</span>-->
                            <span style='font-weight: lighter'> /
                        <span v-if="member.is_leave==='Y'" style='color: #F56C6C'>{{ member.is_leave_alias }}</span>
                        <span v-else>{{ member.is_leave_alias }}</span>
                      </span>
                          </el-checkbox>
                        </el-checkbox-group>
                      </div>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item>
                  <el-button type='primary' @click='addToMembers'>添加成员</el-button>
                </el-form-item>
              </el-form>
              <div>
              </div>
            </div>
          </el-card>
        </el-col>


      </el-row>
    </el-dialog>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UserSelect from '@/pages/commerce/components/UserSelect'
import EcpDeptSelect from '@/pages/commerce/kol_mapper/EcpDeptSelect'

export default {
  name: 'CreateMember',
  components: { UserSelect, EcpDeptSelect },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  props: {
    dialogTitle: {
      type: String,
      default() {
        return '奖金分配小组成员选择'
      }
    },
    selectUrl: {
      type: String,
      default() {
        return 'getMcnGroupDeptOption'
      }
    },
    saveUrl: {
      type: String,
      default() {
        return 'addMembersToBonusData'
      }
    }
  },

  data() {
    return {
      loadingCommit: false,
      //userid:钉钉用户ID
      formData: { ym: null, group_id: '', group_name: '', members: [] },
      rules: {
        ym: { required: true },
        group_id: { required: true },
        userid: { required: true }
      },
      props: {
        label: 'name',
        children: 'zones'
      },
      count: 1,
      checkAll: false,
      checkedMembers: [],
      members: [],
      isIndeterminate: true,
      selectId: ''
    }
  },
  methods: {
    handleLeaderSelect(item) {
      let newData = {
        userid: item.value,
        name: item.label
      }
      if (this.formData.members.findIndex(value => value.userid === newData.userid) === -1) {
        this.formData.members.push(newData)
      }
    },
    onOpen() {
      // this.getAInfo()
      this.checkedMembers = []
      this.members = []
      this.isIndeterminate = true
      this.checkAll = false
      this.loadingCommit = false
      this.formData.members = []
    },
    onClose() {
      this.$refs['elForm'].resetFields()

    },
    close() {
      this.$emit('update:visible', false)
    },
    encryptMobile(phone) {
      phone = '' + phone
      let reg = /(\d{3})\d{4}(\d{4})/ //正则表达式

      return phone.replace(reg, '$1****$2')
    },
    handleConfirm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) return
        this.loadingCommit = true
        this.saveInfo()
      })
    },
    handleClose(tag) {
      this.formData.members.splice(this.formData.members.indexOf(tag), 1)
    },
    async saveInfo() {
      // this.formData.members = this.checkedMembers
      // console.log(postData)
      if (this.formData.members.length === 0) {
        this.$notify.warning('请先添加成员')
        this.loadingCommit = false
        return false
      }
      let date = {
        year: this.formData.ym.split('-')[0],
        month: this.formData.ym.split('-')[1]
      }
      let mapMembers = this.formData.members.map((i) => {
        return { 'name': i.name, userid: i.userid }
      })
      this.formData = { ...this.formData, ...date }
      this.formData.members = mapMembers
      delete this.formData.ym
      await this.$api[this.saveUrl](this.formData)
      this.loadingCommit = false
      this.$emit('saved')
      this.close()
    },

    // eslint-disable-next-line no-unused-vars
    selectDept(valArr, labelArr) {
      //加载小组成员
      // this.loadMembers({dpt_id:valArr})
      let group_id = null
      // let group_name = null;

      if (valArr && valArr.length > 0) {
        let len = valArr.length
        group_id = valArr[len - 1].dpt_id
        // group_name = labelArr[len - 1];
        //加载成员列表
        this.checkedMembers = []
        this.members = []
        this.isIndeterminate = true
        this.checkAll = false
        this.loadMembers({ dpt_id: group_id })
      }
    },
    selectCurrentDept(arr) {
      //加载小组成员
      // this.loadMembers({dpt_id:valArr})
      let group_id = null
      let group_name = null
      let dept_id = null
      let dept_name = null
      if (arr && arr.length >= 2) {
        let len = arr.length
        group_id = arr[len - 1].dpt_id
        group_name = arr[len - 1].name
        dept_id = arr[len - 2].dpt_id
        dept_name = arr[len - 2].name
        this.formData.group_id = group_id
        this.formData.group_name = group_name
        this.formData.dept_id = dept_id
        this.formData.dept_name = dept_name
      } else if (arr && arr.length === 1) {
        this.formData.group_id = arr[0].dpt_id
        this.formData.group_name = arr[0].name
        this.formData.dept_id = arr[0].parent_id
        this.formData.dept_name = '公司'
      }
    },
    async loadMembers(searchCondition) {
      this.members = []
      searchCondition.page_size = 50
      let { list, pages } = await this.$api.getDptUsers(searchCondition)
      this.members = [...this.members, ...list]
      searchCondition.page_size = pages.page_size
      searchCondition.current_page = pages.current_page
      for (let i = pages.offset + list.length; i < pages.total; i += pages.page_size) {
        searchCondition.current_page += 1
        let { list } = await this.$api.getDptUsers(searchCondition)
        this.members = [...this.members, ...list]
      }

    },
    handleCheckAllChange(val) {
      this.checkedMembers = val ? [...this.members] : []
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.members.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.members.length
    },
    addToMembers() {
      //添加到成员(已添加，则跳过)
      this.checkedMembers.forEach((item) => {
        if (this.formData.members.findIndex(value => value.userid === item.userid) === -1) {
          this.formData.members.push(item)
        }
      })
    }
  }
}
</script>

<style scoped>
>>> .el-card__header {
  padding: 10px;
  font-size: 16px;
}

.dpt {
  min-height: 400px;
  height: 100px;
  max-height: 800px;
  overflow-y: scroll;
  /*border: #ff3176 1px solid;*/
  padding: 20px;
  margin-bottom: 20px;
}

.member {
  min-height: 400px;
  height: 100px;
  max-height: 800px;
  overflow-y: scroll;
  /*border: #ff3176 1px solid;*/
  padding: 20px;
  margin-bottom: 20px;
}
</style>

<style>
.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
