<template>
  <div style='margin: auto' class='ecp-table-child'>
    <el-table :span-method='spanMethod' :data='dataList' show-summary :summary-method='getSummaries' border>
      <el-table-column type='index' label='序号' min-width='55' align='center'></el-table-column>
      <el-table-column prop='group_name' label='项目组' min-width='180' align='center'>
        <template slot-scope='{row}'>
          {{ row.group_name }} <i class='el-icon-edit' style='cursor: pointer;' @click='editBtn(row)'></i>
        </template>
      </el-table-column>
      <el-table-column prop='position' label='职位' min-width='180' align='center'></el-table-column>
      <el-table-column prop='name' label='姓名' min-width='180' align='center'></el-table-column>
      <el-table-column prop='bonus' label='组奖金' min-width='120' align='center'>
        <template slot-scope='{row}'>
          <span class='money'>{{ row.bonus &gt; 0 ? row.bonus : 0 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop='ratio' label='分配比例' min-width='180' align='center'>
        <template slot-scope='{row,column}'><span class='ratio'>{{
            $utils.numberFormat(row[column.property], 2)
          }}</span></template>
      </el-table-column>
      <el-table-column prop='my_bonus' label='奖金金额' min-width='120' align='center'>
        <template slot-scope='{row}'>
          <span class='money'>{{ row.my_bonus }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync='dialogStore' title='奖金分配'
               @close='close' v-if='dialogStore' width='80%' center>

      <div class='ecp-table'>
        <el-table :span-method='spanMethod' :data='dataList' show-summary :summary-method='getSummaries' border>
          <el-table-column type='index' label='序号' min-width='55' align='center'></el-table-column>
          <el-table-column prop='group_name' label='项目组' min-width='180' align='center'>
          </el-table-column>
          <el-table-column prop='position' label='职位' min-width='180' align='center'></el-table-column>
          <el-table-column prop='name' label='姓名' min-width='180' align='center'></el-table-column>
          <el-table-column prop='bonus' label='组奖金' min-width='120' align='center'>
            <template slot-scope='{row}'>
              <span class='money'>{{ row.bonus &gt; 0 ? row.bonus : 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop='ratio' label='分配比例' min-width='180' align='center'>
            <editable-cell v-if='setAuthority("ecp_bonus_rate_save")&&project.status<=2'
                           :show-input='row.editMode'
                           slot-scope='{row,column}'
                           v-model='row[column.property]'
                           @change="editVal(row,'ratio')" :is-input-number='true'>
                          <span slot='content'>
                            <span class='ratio'>{{ $utils.numberFormat(row[column.property], 2) }}</span>
                            <i class='el-icon-edit default-edit-icon' v-if='showEditIcon'></i>
                          </span>
            </editable-cell>
            <template v-else slot-scope='{row,column}'><span
              class='ratio'>{{ $utils.numberFormat(row[column.property], 2) }}</span></template>
          </el-table-column>
          <el-table-column prop='my_bonus' label='奖金金额' min-width='120' align='center'>
            <template slot-scope='{row}'>
              <span class='money'>{{ row.my_bonus }}</span>
            </template>
          </el-table-column>
          <el-table-column label='操作' min-width='120' align='center'>
            <template slot-scope='{row,$index}'>
              <el-button type='text' @click='handleRemove(row,$index)'
                         v-if='setAuthority("ecp_bonus_del_member")&&project.status<=2'><i
                class='el-icon-remove'></i>移除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot='footer' class='dialog-footer'>
      <el-row>
        <el-col :span='2'>
          <el-button @click='revokeBtn(2)' type='warning'
                     v-if='project.status > 1&& userPermissions.indexOf("ecp_bonus_cancel_check")>-1' ><i
            class='iconfont icon-fanshenhe'></i> 撤回重审
          </el-button>
          <el-button v-else disabled>
            <i class='iconfont icon-fanshenhe'></i> 撤回重审
          </el-button>
        </el-col>
        <el-col :span='22' style='text-align: right'>
          <el-tag :type="checkStatus()['type']" effect='plain' class='status-tag' size='large'>
          状态 - {{
              checkStatus()['label']
            }}
        </el-tag>
        <el-button @click="saveConfirm('save')"
                   :disabled='!(project.status<=2)'
                   v-if="setAuthority('ecp_bonus_rate_save')">保存
        </el-button>

        <el-button v-if="project.status<=1&&setAuthority('ecp_bonus_submit_manager')" type='primary'
                   @click='saveConfirm(2)'>提交总监审核</el-button>
        <el-button :type='`${project.status > 1 ? "success" : "default"}`'
                   @click='saveConfirm(3)'
                   :disabled='project.status && project.status > 2'
                   v-if="setAuthority('ecp_bonus_manager_check')">
          {{ project.status && project.status > 2 ? '总监审核完毕' : '总监审核' }}
        </el-button>
      <el-button :type='`${project.status && project.status > 3 ? "success" : "default"}`'
                 :disabled='project.status && project.status > 3'
                 v-if="setAuthority('ecp_bonus_finish_check')" @click='saveConfirm(4)'>
                    {{ project.status && project.status > 3 ? '最终审核完毕' : '最终审核' }}
     </el-button>

        </el-col>
      </el-row>  </span>

    </el-dialog>
  </div>

</template>

<script>
import EditableCell from '@/components/EditableCell'
import { mapGetters } from 'vuex'

export default {
  name: 'TeamBonusMember',
  components: { EditableCell },
  props: {

    memberList: {
      type: Array,
      default() {
        return []
      }
    },
    project: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    dataList: {
      get() {
        return this.memberList || []
      },
      set(val) {
        this.$emit('update:memberList', val)
      }
    }
  },
  data() {
    return {
      searchCondition: {},
      total: 0,
      showEditIcon: true,
      mergeFields: ['group_name', 'bonus'],//合并的字段
      spanObj: {},//合并的数据坐标Map
      positionObj: {},//合并的位置记录
      dialogStore: false,
      editRow: [],
      editData: []
      // dataList: [{ dept_name: '项目1组', year: 2023, month: 5, group_bonus: 12000, ratio: 0.2, my_bonus: 1000 }]
    }
  },
  watch: {
    dataList: {
      immediate: true,
      handler(val) {
        if (val?.length != 0) {
          this.rowspan()
        }
      }
    }
  },
  methods: {
    close() {
      this.$emit('getList')
    },
    setAuthority(val) {
      return this.userPermissions.indexOf(val) > -1
    },
    checkStatus() {
      if (this.project.status === 4)
        return { type: 'success', label: '已完结' }
      else if (this.project.status === 3) {
        return { type: 'warning', label: '待最终审核' }
      } else if (this.project.status === 2) {
        return { type: 'warning', label: '待总监审核' }
      } else {
        return { type: 'info', label: '待提交总监审核' }
      }
    },
    revokeBtn() {
      this.$confirm(`此操作将撤回审核状态至[待总监审核], 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // this.handleStatus(2)
        await this.$api.cancelCheckEcpBonusGroup({ id: this.project.id })
        this.$emit('getList')
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },


    async handleStatus(status) {
      let data = await this.$api.saveEcpBonusGroup({ id: this.project.id, status: status })
      if (data) {
        this.msgSuccess('修改成功')
        this.dialogStore = false
        this.$emit('getList')
      }
    },
    saveConfirm(status) {
      let modeMsg = ''
      if (status === 'save') {
        modeMsg = '保存'
      } else if (status === 2) {
        modeMsg = '提交总监审核'
      } else if (status === 3) {
        modeMsg = '提交总监审核'
      } else if (status === 4) {
        modeMsg = '提交最终审核'
      }
      let msg = `确认${modeMsg}【${this.project.group_name}】【${this.project.year}】【${this.project.quarter}】季度奖金分配明细, 是否继续?`

      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (status === 'save') {
          this.editVal(status, 'save')
        } else
          this.handleStatus(status)
      })
    },

    editBtn() {
      this.dialogStore = true
      this.editData = []

    },
    spanMethod({ rowIndex, column }) {
      const fields = this.mergeFields
      if (column.property && fields.indexOf(column.property) > -1 && this.spanObj[column.property]) {
        const _row = this.spanObj[column.property][rowIndex] || 0
        const _col = _row || 0 > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    rowspan() {
      const fields = this.mergeFields //合并的字段
      fields.forEach((fields) => {
        this.dataList.forEach((item, index) => {
          if (index === 0) {
            this.spanObj[fields] = []
            this.spanObj[fields].push(1)
            this.positionObj[fields] = 0
          } else {
            // 合并数据：若和上一行的数据相同，则该字段合并行数加1
            if (this.dataList[index][fields] === this.dataList[index - 1][fields]) {
              this.spanObj[fields][this.positionObj[fields]] += 1
              this.spanObj[fields].push(0)
            } else {
              this.spanObj[fields].push(1)
              this.positionObj[fields] = index
            }
          }
        })
      })

    },
    // eslint-disable-next-line no-unused-vars
    async handleRemove(row, $index) {
      this.dataList.splice($index, 1)
      let id = this.$api.deleteEcpBonusMonthMember(row.id)
      if (id) {
        this.$notify.success(`移除项目组【${row.group_name}】参与奖金分配的成员【${row.name}】`)
      }
    },
    async editVal(row, colName) {
      if (colName === 'ratio') {
        row.my_bonus = row.bonus * row.ratio / 100
        //保存 分配的奖金占比和金额
        this.editData.push({ id: row.id, ratio: row.ratio, my_bonus: row.my_bonus })
      }
      if (colName === 'save') {
        let i = this.editData.length
        for (const item of this.editData) {
          await this.$api.saveEcpBonusMonthMember(item)
          i--
        }
        if (i === 0) {
          // this.dialogStore = false
          this.msgSuccess('修改成功')
        }
      }
      // this.$emit('editVal', this.dataList)// 通知父组件 修改后的表格数据
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = ''
          return
        }
        if (['ratio', 'my_bonus'].indexOf(column.property) > -1) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[index] += ' '
          } else {
            sums[index] = 'N/A'
          }

          switch (column.property) {
            case 'ratio':
              sums[index] = this.$utils.numberFormat(sums[index], 0) + ' %'
              break
            // case 'month_amount':
            //   sums[index] = '¥' + this.moneyFormat(sums[index])
            //   break
            default:
              sums[index] = '¥ ' + this.$utils.numberFormat(sums[index], 2)
              break
          }
        }

      })

      return sums
    }
  }
}
</script>
<style scoped>

.status-tag {
  margin-right: 10px;
}
</style>
