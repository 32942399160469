<template>
  <div style='margin-top: 10px'>
<!--    <h3>招商部-奖金分配及审核</h3>-->
    <div>
      <el-form :model='searchCondition' size='mini' inline>
        <el-form-item>
          <el-date-picker
            v-model='searchCondition.ym'
            type='month'
            format='yyyy年MM月'
            value-format='yyyy-MM'
            placeholder='选择月度' @change='handleSearch' style='min-width: 140px'>
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type='primary' @click='handleSearch'>查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class='default-table'>
      <el-table :data='dataList' border>
        <el-table-column label='序号' type='index' align='center' width='88'></el-table-column>
        <el-table-column label='部门' align='center'>
          <template>
            <span>招商部</span>
          </template>
        </el-table-column>
        <el-table-column label='姓名' prop='name' align='center'></el-table-column>
        <el-table-column label='职位' prop='position' align='center'></el-table-column>
        <el-table-column label='月份' prop='month' align='center'>
          <template slot-scope='{row}'>
            <span>{{ row.year }}年{{ row.month }}月份</span>
          </template>
        </el-table-column>
        <el-table-column
          :prop='`quotient`'
          label='浮动系数'
          min-width='100' header-align='center' align='right'>
          <template slot-scope='{row,column}'>
            <span>{{ row[column.property] ? $utils.numberFormat(row[column.property], 2) : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :prop='`guard_gmv`'
          :label='`月度目标`'
          min-width='100' header-align='center' align='right'>
          <template slot-scope='{row,column}'>
            <span>{{ row[column.property] ? $utils.numberFormat(row[column.property], 2) : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :prop='`real_gmv`'
          :label='`月度实际`'
          min-width='100' header-align='center' align='right'>
          <template slot-scope='{row,column}'>
            <span>{{ row[column.property] ? $utils.numberFormat(row[column.property], 2) : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :prop='`bonus`'
          :label='`月度奖金`'
          min-width='100' header-align='center' align='right'>
          <template slot-scope='{row,column}'>
            <span>{{ row[column.property] ? $utils.numberFormat(row[column.property], 2) : '' }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!--      分页-->
      <pagination :total.sync='total' :limit.sync='pageData.page_size' :page.sync='pageData.current_page'
                  @pagination='getList' />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MerchantBonus',
  data() {
    return {
      searchCondition: {
        // year: null,
        // month: null,
        ym: null
      },
      dataList: [],
      pageData: { page_size: 10, current_page: 1 },
      total: 0
    }
  },
  methods: {
    handleSearch() {
      this.pageData.current_page = 1
      this.getList()
    },
    handleCondition() {
      let condition = {}
      if (this.searchCondition.ym) {
        let ymArr = this.searchCondition.ym.split('-')
        condition['year'] = ymArr[0]
        condition['month'] = ymArr[1]
      }

      return condition
    },
    async getList() {
      this.dataList = []
      let searchCondition = this.handleCondition()
      searchCondition = Object.assign(searchCondition, this.pageData)
      let { list, pages } = await this.$api.getEcpMerchantMonthList(searchCondition)
      this.dataList = list
      this.total = pages.total
      this.pageData.page_size = pages.page_size
      this.pageData.current_page = pages.current_page
    }
  },
  created() {
    // 默认显示上个月的奖金分配记录
    let date = new Date()
    date.setMonth(date.getMonth() - 1)
    this.searchCondition.ym = `${date.getFullYear()}-${date.getMonth() + 1}`
    this.handleSearch()
  }
}
</script>

<style scoped>

</style>