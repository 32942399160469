<template>
  <div>
    <el-dialog v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose' width='1000px' :title='dialogTitle'>
      <div class='card'>
        <div class='card-box'>
          <el-form :inline='false' :model='formData' label-width='100px' label-position='right'>
            <el-form-item label='月份'>
              <el-date-picker
                v-model='ym'
                type='month'
                format='yyyy年MM月'
                value-format='yyyy-MM'
                placeholder='选择月度' @change='selectMonth' style='min-width: 140px'>
              </el-date-picker>
            </el-form-item>
            <BonusMemberSelection style='height: 400px;overflow-y: scroll' @selected='selectedMembers' />
            <el-form-item label='奖金金额：'>
              <el-input v-model='formData.bonus' placeholder='组奖金金额' clearable
                        oninput="value=value.replace(/[^\d^\.^\-]/g,'')" >
<!--                <el-button type='primary' slot='append'>计算奖金</el-button>-->
              </el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div slot='footer' style='text-align: center'>
        <el-button type='primary' size='medium' @click='handleAdd' :loading='loadingCommit' :disabled='loadingCommit'>
          保存
        </el-button>
        <el-button size='medium' @click='close'>
          关闭
        </el-button>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EcpDeptSelect from '@/pages/commerce/kol_mapper/EcpDeptSelect'
import BonusMemberSelection from '@/pages/commerce/bonus/BonusMemberSelection'

export default {
  name: 'CreateEditBonusMonth',
  components: { BonusMemberSelection, EcpDeptSelect },
  props: {
    id: {
      type: [String, Number],
      default() {
        return null
      }
    },
    searchCondition: {
      type: Object,
      default() {
        return {
          year: null, quarter: null
        }
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    dialogTitle() {
      return `${this.id ? '修改（ID：' + this.id + '）' : '添加'} 项目组奖金分配`
    }
  },
  data() {
    return {
      ym: null,
      formData: {
        'year': null,
        'month': null,
        'quarter': null,
        'group_id': null,
        'group_name': null,
        'bonus': null,
        'last_total_profit': null,
        members: []
      },
      loadingCommit: false
    }
  },
  methods: {
    onOpen() {
      this.loadingCommit = false
    },
    onClose() {

    },
    close() {
      this.$emit('update:visible', false)
    },
    async handleAdd() {
      let id = await this.$api.saveEcpBonusMonth(this.formData)
      if (id) {
        this.msgSuccess('操作成功')
        this.close()
      }
    },
    selectMonth() {
      let ymArr = this.ym.indexOf('-') > -1 ? this.ym.split('-') : []
      if (ymArr.length === 0) {
        this.$notify.warning('月份必须选')
        return false
      }
      //获取：年份 月份 季度
      this.formData.year = ymArr[0]
      this.formData.month = ymArr[1] / 1
      this.formData.quarter = Math.ceil(this.formData.month / 3)
    },
    selectedMembers(data) {
      this.formData.group_id = data.group_id
      this.formData.group_name = data.group_name
      this.formData.dept_id = data.dept_id
      this.formData.dept_name = data.dept_name
      this.formData.members = data.selectMembers
    }

  }
}
</script>

<style scoped>
.card {
  line-height: 400px;
  width: 100%;
}

.card-title {
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 30px;
  margin-bottom: 5px;
}

.card-box {
  padding: 10px 20px;
  line-height: 750px;
  height: 500px;
  border: #2c2727 1px dashed;
  margin-bottom: 20px;
  /*overflow-y: scroll;*/
}
</style>
