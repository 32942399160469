<template>
  <div>
    <el-row class='table-search'>
      <el-col :span='18'>
        <el-form :model='searchCondition' :inline='true' size='mini'>
          <el-form-item>
            <EcpDeptSelect class='select-custom' ref='ecp_dept'
                           @handleSelect='selectDept'/>
            <!--            <DeptCascaderScope-->
            <!--              :showFirstGroup='false' :type="{type:'ecp_bonus_data'}" ref='refDeptCascaderScope'-->
            <!--              :show-first-group='true'-->
            <!--              :selectUrl="'optionEcpBonusMember'" style='display: inline-block'-->
            <!--              @selectedDept='selectDept' @firstGroup='currentGroup' />-->
          </el-form-item>

          <el-form-item>
            <el-date-picker
              v-model='searchCondition.ym'
              type='month'
              format='yyyy年MM月'
              value-format='yyyy-MM'
              placeholder='选择月度' @change='handleSearch' style='min-width: 140px'>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type='primary' @click='handleSearch' icon='el-icon-search'
                       v-if='hasAuthority("ecp_bonus_list")'>查询
            </el-button>
            <el-button plain type='warning' @click='initBonusData' :loading='initLoading' :disabled='initLoading'
                       icon='el-icon-refresh' v-if='hasAuthority("ecp_bonus_init")'>
              {{ initLoading ? '初始化中...' : '初始化' }}
            </el-button>
            <el-button icon='el-icon-plus' type='primary' @click='showAddMember=!showAddMember'
                       v-if='hasAuthority("ecp_bonus_add_member")'>新增
            </el-button>
            <el-button v-if="hasAuthority('ecp_bonus_export')" v-loading='downLoading'
                       @click='exportDownload'>
              <i :class="downLoading?'el-icon-loading':'el-icon-download'">导出</i>
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <div class='ecp-table'>
        <el-table ref='groupBonusTable'
                  v-loading='tableLoading'
                  :data='dataList'
                  @row-dblclick='expandRow'
                  show-summary
                  :summary-method='getSummaries'
                  @expand-change='expandFun' row-key='id'
                  :expand-row-keys='expands'
                  highlight-current-row
                  @selection-change='handleSelectionChange'
                  border>
          <el-table-column type='expand'>
            <template slot-scope='{row}'>
              <TeamBonusMember @getList='getList' :project='row' :member-list='row.members'
                               style='width: 99%;' />
            </template>
          </el-table-column>
          <el-table-column label='序号' type='index' width='80' align='center'></el-table-column>
          <el-table-column label='负责人' prop='m_leader' align='center' min-width='200'></el-table-column>

          <el-table-column label='项目组' prop='group_name' align='center' min-width='200'></el-table-column>
          <el-table-column label='时间（年月）' prop='month' align='center' min-width='180'>
            <template slot-scope='{row}'>
              <i class='el-icon-time'></i>
              <span style='margin-left: 10px'>{{ row.year || ' - ' }}年{{ row.month || ' - ' }}月</span>
            </template>
          </el-table-column>
          <el-table-column label='成员数量' prop='member_total' align='center' min-width='120'>
            <template slot-scope='{row}'>
              <span>{{ row.members ? row.members.length : 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column label='组奖金' prop='bonus' header-align='center' align='right' min-width='220'>
            <template slot-scope='{row,column}'><span
                class='money'>{{ $utils.numberFormat(row[column.property], 2) }}</span></template>
          </el-table-column>
          <el-table-column label='状态' prop='status' align='center' min-width='120'>
            <template slot-scope='{row}'>
              <el-tag
                :type='calcStatusType(row.status) '
                disable-transitions> {{ row.status | statusFilter }}
              </el-tag>

            </template>
          </el-table-column>
          <el-table-column label='操作' width='100' align='center'>
            <template slot-scope='{row}'>
                            <el-button v-if='userPermissions.indexOf("ecp_bonus_add_member")>-1&&row.status<=2' icon='el-icon-plus' type='text'
                                       @click='handleAddMember(row)'>添加成员
                            </el-button>
              <el-button type='text' icon='el-icon-delete' @click='handleDelete(row)'
                         v-if='userPermissions.indexOf("ecp_bonus_del_member")>-1&&row.status<=3'>删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-row>

    <!--    //添加-->
    <!--    <CreateEditBonusMonth :visible.sync='dialogVisible' :id='currentId' :search-condition='searchCondition'-->
    <!--                          @close='getList' />-->
    <CreateMember :saveUrl="'saveEcpBonusMember'" :selectUrl="'optionEcpBonusMember'" :visible.sync='showAddMember'
                  v-if='showAddMember' @saved='doSearch' />


        <AddMember :id='currentId' :visible.sync='dialogVisibleMember' @close='handleSearch'></AddMember>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TeamBonusMember from '@/pages/commerce/bonus/TeamBonusMember'
import CreateEditBonusMonth from '@/pages/commerce/bonus/CreateEditBonusMonth'
import EditableCell from '@/components/EditableCell'
import AddMember from '@/pages/commerce/bonus/AddMember'
import FileSaver from 'file-saver'
import CreateMember from '@/components/CreateMember.vue'
import EcpDeptSelect from '@/pages/commerce/kol_mapper/EcpDeptSelect'

export default {
  name: 'TeamBonus',
  components: { AddMember, EditableCell, CreateEditBonusMonth, TeamBonusMember, CreateMember, EcpDeptSelect },
  computed: {
    ...mapGetters(['userPermissions'])
  },

  data() {
    return {
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      showAddMember: false,
      // dialogVisible: false,
      dialogVisibleMember: false,
      currentId: null,
      searchCondition: { ym: null },
      pageData: { page_size: 10, current_page: 1 },
      total: 0,
      // dialogVisible:false,
      initLoading: false,
      dataList: [
        // {
        //   id: 1,
        //   dept_name: '项目1组',
        //   year: 2023,
        //   month: 5,
        //   member_total: 10,
        //   bonus: 12000,
        //   status: 1,
        //   status_alisa: '待审核',
        //   items: [
        //     { dept_name: '项目1组', position: '总监', year: 2023, month: 5, group_bonus: 12000, ratio: 0.2, my_bonus: 1000 },
        //     { dept_name: '项目1组', position: '专员', year: 2023, month: 5, group_bonus: 12000, ratio: 0.2, my_bonus: 1000 },
        //     { dept_name: '项目1组', position: '助理', year: 2023, month: 5, group_bonus: 12000, ratio: 0.2, my_bonus: 1000 }
        //   ]
        // },
        // {
        //   id: 2, dept_name: '项目2组', year: 2023, month: 5, member_total: 12, bonus: 12000, status: 1, items: [
        //     { dept_name: '项目1组', position: '总监', year: 2023, month: 5, group_bonus: 12000, ratio: 0.2, my_bonus: 1000 },
        //     { dept_name: '项目1组', position: '专员', year: 2023, month: 5, group_bonus: 12000, ratio: 0.2, my_bonus: 1000 },
        //     { dept_name: '项目1组', position: '助理', year: 2023, month: 5, group_bonus: 12000, ratio: 0.2, my_bonus: 1000 }
        //   ]
        // },
        // { id: 3, dept_name: '项目3组', year: 2023, month: 5, member_total: 14, bonus: 12000, status: 1 },
        // { id: 4, dept_name: '项目4组', year: 2023, month: 5, member_total: 16, bonus: 12000, status: 1 }
      ],
      expandRowKey: 1,
      downLoading: false,
      expands: [],
      tableLoading: false


    }
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        '0': '待审核',
        '1': '待提交审核',
        '2': '待总监审核',
        '3': '待最终审核',
        '4': '最终审核完结'
      }
      return statusMap[status]
    }
  },
  methods: {
    calcStatusType(status) {
      let type = ''
      switch (status) {
        case 1:
          type = 'primary'
          break
        case 2:
          type = 'warning'
          break
        case 3:
          type = 'warning'
          break
        case 4:
          type = 'success'
          break
        default:
          type = 'info'
          break
      }
      return type
    },
    async exportDownload() {
      this.downLoading = true
      try {
        var isFileSaverSupported = !!new Blob
        if (isFileSaverSupported) {
          let searchCondition = {
            year: this.searchCondition.ym.slice(0, 4),
            month: this.searchCondition.ym.slice(5, 7)
          }
          let response = await this.$api.downEcpBonusList(searchCondition)
          let data = response.data
          let contentType = response.headers['content-type']
          let name = `${this.searchCondition.ym.slice(0, 4)}年奖金分配一览`
          let blob = new Blob([data], {
            type: contentType
          })
          FileSaver.saveAs(blob, decodeURI(name + '.xlsx'), { autoBom: true })
          this.downLoading = false

        } else {
          this.$message.warning('浏览器不支持导出文件')
          this.downLoading = false

        }
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        this.downLoading = false

        // alert(JSON.stringify(e))
      }
    },
    async setGroupInfo(group_id, group_name) {
      // this.$refs['refDeptCascaderScope'].initVal(group_id)
      this.searchCondition.group_id = group_id
      this.searchCondition.group_name = group_name
      this.getList()
    },
    async currentGroup(group) {
      if (group && group.dpt_id) {
        await this.setGroupInfo(group.dpt_id, group.name)
      }
    },
    async selectDept(arr) {
      if(arr&&arr.length>0){
        let group_id = null
        let group_name = null
        group_id = arr[arr.length - 1].dpt_id
        group_name = arr[arr.length - 1].name
        await this.setGroupInfo(group_id, group_name)
      }else {
        await this.setGroupInfo()

      }


    },
    handleSearch() {
      this.pageData.current_page = 1
      this.getList()
    },
    initBonusData() {
      this.$confirm(`是否确认初始化【${this.searchCondition.ym}】数据`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // String(item.month.month < 10 ? '0' + item.month : item.month)

        let searchCondition = {
          year: this.searchCondition.ym.slice(0, 4),
          month: this.searchCondition.ym.split('-')[1]
        }
        let del_count = await this.$api.initEcpBonusMember(searchCondition)
        if (del_count) {
          this.msgSuccess(`初始化成功`)
          await this.getList()
        }
      })
    },
    doSearch() {
      this.msgSuccess('新增成功')
      this.getList()
    },
    handleAdd() {
      this.dialogVisible = true
    },

    handleDelete(row) {
      let id = row.id
      this.$confirm(`是否确认删除 【${row.year}年${row.month}月 ${row.group_name}】的奖金数据（包括成员分配比例）`, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let del_count = await this.$api.deleteEcpBonusMonth({ id: id })
        if (del_count) {
          this.msgSuccess(`已删除[${del_count}]条数据`)
          await this.getList()
        }
      })
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /**
     * 记录当前展开页面
     * @param row
     */

    expandFun(expandedRows, expanded) {
      // this.expands=expanded
      if (expanded.length > 1) {
        this.$refs.groupBonusTable.toggleRowExpansion(expanded[0])
        localStorage.setItem('expandBonus', expandedRows.id)//设置缓存数据，(key,value)
      }

    },
    expandRow(row) {
      this.$refs.groupBonusTable.toggleRowExpansion(row.id)
      this.expands = []
      this.expands.push(row.id)
      localStorage.setItem('expandBonus', row.id)//设置缓存数据，(key,value)
    },
    async getList() {
      this.tableLoading = true
      //加载成员
      this.dataList = []
      let searchCondition = Object.assign(this.searchCondition, { page_size: 100, current_page: 1 })
      //触发查询
      if (!searchCondition.group_id) {
        delete searchCondition.group_id
        delete searchCondition.group_name
      }
      let { list, pages } = await this.$api.getEcpBonusMonthList(searchCondition)
      list.forEach((item) => {
        if (item?.members?.length > 0) {
          item.members.map((_) => {
            _.bonus = item.bonus
            return _
          })
        }
      })
      this.dataList = [...list]
      this.total = pages.total
      this.pageData.page_size = pages.page_size
      this.pageData.current_page = pages.current_page
      let data = localStorage.getItem('expandBonus')
      if (data) {
        this.expands.push(data)
      }
      this.tableLoading = false
    },
    handleAddMember(row) {
      // this.$notify.info(`${row.group_name}`)
      this.currentId = row.id
      this.dialogVisibleMember = true
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = ''
          return
        }
        if (['bonus'].indexOf(column.property) > -1) {
          const values = data.map((item) => Number(item[column.property]))
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[index] += ' '
          } else {
            sums[index] = 'N/A'
          }

          switch (column.property) {
            default:
              sums[index] = '¥ ' + this.moneyFormat(sums[index])
              break
          }
        }
      })

      return sums
    },
    moneyFormat(val) {
      return this.$utils.numberFormat(val, 2)
    },

  },
  created() {
    // 默认显示上个月的奖金分配记录
    let date = new Date()
    date.setMonth(date.getMonth() - 1)
    this.searchCondition.ym = `${date.getFullYear()}-${date.getMonth() + 1}`
    this.handleSearch()
  }

}
</script>

<style scoped>


.panel-box {
  border: #0C0C0C 1px dashed;
  display: inline;
}

.bonus-quarter {
  /*border: #0C0C0C 1px dashed;*/
  padding: 2px;
  /*background: #0C0C0C;*/
  font-size: 1.1em;
  /*color: #fefefe;*/
}

.bonus {
  /*border: #0C0C0C 1px dashed;*/
  padding: 2px;
  /*background: #0C0C0C;*/
  font-size: 1.1em;
  /*color: #f2f2f2;*/
}


::v-deep.ecp-table .el-table thead {
  background: #eee;
  color: #666;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

::v-deep.ecp-table .el-table__header-wrapper .el-table__header thead tr th {
  background: #EBEEF5;
  padding: 0 0 !important;
}

::v-deep.ecp-table .el-table__fixed-header-wrapper .el-table__header thead tr th {
  background: rgba(248, 248, 248, 1);
  padding: 0 0 !important;
}

::v-deep.ecp-table th, .ecp-table td {
  /*padding: 4px 0;*/

}

::v-deep.ecp-table div.el-table td .cell {
  height: auto !important;
  line-height: 20px;
  font-weight: normal;
  font-size: 12px;

}

/*el-table__cell*/
::v-deep.ecp-table thead th .cell {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  font-weight: 800;
  color: #0C0C0C;
}

::v-deep.ecp-table-child thead tr th .cell {
  height: 30px !important;
  line-height: 30px !important;
  font-size: 12px !important;
  /*font-weight: 800 !important; !**!*/
  color: #0C0C0C !important;
}

::v-deep.ecp-table thead th.is-leaf .cell {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  font-weight: 800;
  color: #0C0C0C;
}


::v-deep.ecp-table-child thead th.is-leaf .cell {
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  font-weight: normal !important;
  color: #0C0C0C;

}

::v-deep.ecp-table-child .el-table__header-wrapper .el-table__header thead tr th {
  background: #fbf2f5;
  padding: 0 0 !important;
}

::v-deep.ecp-table .el-table thead tr, .ecp-table .el-table thead th {
  background: rgb(255, 255, 255) !important;
  padding: 10px 0 !important;
  color: rgba(50, 50, 50, 1);
  font-size: 14px;
  font-weight: normal;

}

::v-deep.ecp-table .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #EBEEF5;
}

.ecp-tip {
  border: #0C0C0C 1px dashed;
  background: rgba(255, 249, 251, 0.98);
  border-radius: 10px;
  padding: 5px 10px;
}
</style>
